.pr_header_wrapper {
  height: 100px;
  width: 100%;
  background-color: #298FFF;
}

.container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

}

.pr_header_components {
  padding-top: 15px;
  padding-left: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  flex-shrink: 1;
}

.pr_header_components li {
  align-self: flex-end;
  color: #FFFFFF;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-weight: 800;
}

.header_components li:nth-child(1):hover {
  cursor: default;
}

.header_components li:hover {
  cursor: pointer;
}

/* .header_components li:not(:nth-child(7)) div {
  width: 100%;
  height: 2px;
  background-color: #FDE512;
  margin-top: 5px;
  visibility: hidden;
} */

/* .header_components li:hover div {
  visibility: visible;
} */

.lang_link {
  text-decoration: none;
  color: black;
}

.lang_link:visited {
  color: #FFFFFF;
}

.donate_btn {
  width: 170px;
  height: 45px;
  border-radius: 25px;
  font-weight: 800;
  cursor: pointer;
  color: #001B56;
}

/* social media */

.social_media {
  padding: 10px 0;
  box-sizing: border-box;
  justify-content: space-around;
  list-style-type: none;
  display: flex;
  background-color: #FFFFFF;
  width: 330px;
  border-radius: 25px;
  box-shadow: -2px 0 black, 0 3px black, 1px 0 black, 0 -1px black;
  align-self: center;
  margin-bottom: 7rem;
}

.social_media li {
  cursor: pointer;
}

.yellow_svg {
  display: none;
}

.social_media li:hover img:nth-child(1) {
  display: none;
}


.social_media li:hover .yellow_svg {
  display: block;
}

/* header_info */

.header_info {
  width: 80%;

  display: flex;
  list-style-type: none;
  justify-content: space-between;
  padding-left: 0;
  margin: 0 auto;
  position: relative;
  top: -60px;
  box-sizing: border-box;

}

.header_info li {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-basis: 23%;
  height: 120px;
  background-color: #FDE512;
  border-radius: 15px;
  box-shadow: -2px 0 #815A00, 0 3px #815A00, 1px 0 #815A00, 0 -1px #815A00;
  padding: 10px 0;
  font-size: 1.1rem;
}

.divider {
  height: 2px;
  width: 80%;
  margin: 0 auto;
  background-color: #F5D80F;
}

.quantity {
  color: #FFFFFF;
  font-size: 2.31rem;
  font-weight: 800;
  text-shadow: -2px -2px #815A00, 2px 2px #815A00, -2px 2px #815A00, 2px -2px #815A00;
  ;
}

.header_info_type {
  color: #A37200;
  font-weight: 600;
}

.currency {
  display: inline-block;
  padding-left: 10px;
  font-weight: 800;
  color: #815A00;
}

/* context */

.pr_context_container {
  width: 80%;
  margin: 0 auto;
}

.pr_context_title {
  margin-top: 3rem;
  font-size: 2rem;
  color: #298FFF;
  font-weight: 800;
}

.pr_title_main_parts {
  display: inline-block;
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #001B56;
  font-weight: bold;
  margin: 0 !important;
  margin-top: 2rem !important;
}

.pr_context_main {
  text-align: justify;
}

.pr_context_main span {
  display: block;
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-left: 0.5rem;
}

.pr_sub_title_main_part {
  margin: 0 !important;
  margin-top: 1rem !important;
}

.pr_c_footer {
  margin-top: 2rem;
}

.pr_c_footer span {
  margin: 1rem 0 !important;
}

.sub_points {
  margin-left: 2rem;
}