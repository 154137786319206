.about_us_container {
    width: 80%;
    margin: 0 auto;
    padding-top: 13rem;
}

.about_us_info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.about_us_info div {
    flex-basis: 35%;
    text-align: justify;
    font-size: 1.3rem;
    color: #001B56
}

.text_block {
    flex-basis: 50% !important;
}

.bottom {
    align-self: flex-end;
    padding-bottom: 8rem;
}

.aboutUs_title {
    display: inline-block;
    font-size: 2.06rem;
    color: #318BFF;
    font-weight: 800;
    padding-bottom: 2rem;
}