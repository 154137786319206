.nft_main_container {
    display: flex;
    width: 80%;
    margin: 0 auto;

}

.nft_main_container>div {
    display: flex;
    align-items: center;
    width: 80%;
    margin: 0 auto;
}

.nft_main_container div div:nth-child(2) {
    margin-left: 2rem;
}

.viewbox_main {
    text-transform: uppercase;
    padding: 10px 0;
    box-sizing: border-box;
    justify-content: space-around;
    list-style-type: none;
    display: flex;
    background-color: #FDE512;
    width: 330px;
    border-radius: 25px;
    box-shadow: -2px 0 #A47400, 0 3px #A47400, 1px 0 #A47400, 0 -1px #A47400;
    align-self: center;
    font-weight: 800;
    margin-top: 2rem;
    margin-left: 1.5rem;
}

.viewbox_main a {
    color: #FFF;
    text-decoration: none;
    text-shadow: -1px 0 #A47400, 0 1px #A47400, 1px 0 #A47400, 0 -1px #A47400;
}

.sub_title_support {
    color: #318BFF;
    font-weight: 800;
    font-size: 2rem;
    padding-left: 1.5rem;
}

.sub_title_support2 {
    display: inline-block;
    padding-left: 1.5rem;
    padding-top: 2rem;
    line-height: 29px;
}


/* second part */

.nft_main_wrapper {
    box-sizing: border-box;
    background-image: url('/src/images/OtherProjects/nft/bg_nft2.png');
    height: 2340px;
    background-repeat: no-repeat;
}

.nft_main_info_container {
    padding-top: 15rem;
    width: 80%;
    margin: 0 auto;
}

.nft_main_info_title {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 2.2rem;
    color: #FFF;
    text-shadow: -2px 2px #a47400, 0 1px #a47400, 1px 0 #a47400, 0 -1px #a47400;
    margin-left: 10rem;
}

.nft_main_info_box {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background-image: url('/src/images/OtherProjects/nft/boxpic1.png');
    height: 600px;
    background-repeat: no-repeat;
    background-size: inherit;
    background-position: center;
}

.nft_main_info_box2 {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background-image: url('/src/images/OtherProjects/nft/boxpic2.png');
    height: 650px;
    background-repeat: no-repeat;
    background-size: inherit;
    background-position: center;
    background-position-x: 50px;
}

.nft_main_info_box3 {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background-image: url('/src/images/OtherProjects/nft/boxpic3.png');
    height: 650px;
    background-repeat: no-repeat;
    background-size: inherit;
    background-position: center;
}


.nft_main_info_box_info {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 40%;
    left: 20%
}

.nft_main_info_box_info3 {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 30%;
    left: 20%
}

.nft_main_info_box_info,
.nft_main_info_box_info3 span {
    width: 50%;
}

.box_info1 {
    text-transform: capitalize;
    color: #FEB800;
    font-weight: 800;
    font-size: 3rem;
    padding-bottom: 1.5rem;
}

.box_info2 {
    font-size: 1.2rem;
    text-align: justify;
    line-height: 35px;
}

.arrow-snake {
    position: relative;
    height: 85px;
    left: 25%;
    top: -20px;
    margin: 0;
    width: fit-content;
}

.box3_photo {
    position: absolute;
    width: 250px;
    height: 200px;
    background-image: url('/src/images/PhotoReports/image10.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    right: 40px;
    top: -50px;
    border-radius: 25px;
    box-shadow:  -3px -1px black, 0 1px black, 1px 0 black, -3px 3px black;
}

.box3_photo.box3_biggest {
    background-image: url('/src/images/PhotoReports/image7.jpg');
    width: 300px;
    height: 300px;
    top: -70px;
}

.box3_photo.box3_middle {
    background-image: url('/src/images/PhotoReports/image5.jpg');
    width: 250px;
    height: 200px;
    right: 230px;
}

.box3_photo.box3_smallest {
    bottom: 0;
    top: auto;
    top: 170px;
    right: 150px;
}

.nft_pictures_row {
    padding: 3rem 0;
    padding-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.nft_pictures_row img {
    padding-right: 2rem;
    padding-bottom: 1rem;
}

.viewbox_main_container {
    width: 70%;
    margin: 0 auto;
}