.nft_footer_wrapper {
    background-image: url('/src/images/OtherProjects/nft/bg-footer.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 292px;
    margin-top: 5rem;
    box-sizing: border-box;
}

.nft_footer_container {
    width: 70%;
    margin: 0 auto;
}

.nft_footer_container>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8rem;
}

.nft_footer_container>div>div {
    margin-right: 5rem;
}

.nft_footer_container>div>div:nth-child(1) span {
    color: #FFF;
}

.nft_footer_container>div>div:nth-child(2) {
    display: flex;
    align-items: center;

}

.nft_footer_container .footer_sub_title span {
    display: inline-block;
    width: 60%;
}