.header_wrapper_nft {
    height: 950px;
    background-image: url('/src/images/OtherProjects/nft/bg_nft.png');
    background-repeat: no-repeat;
}

.header_container_nft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    padding-top: 2rem;
}

.header_container_nft>div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.header_container_nft .lodi_block {
    flex-wrap: wrap;
    align-self: center;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}

.header_container_nft .lodi_block span {
    padding-left: 1rem;
    width: 80%;
    display: inline-block;
    color: #FDE512;
    font-size: 1.3rem;
    font-weight: 800;
    text-shadow: -2px 0px #0A356E, -1px 2px #0A356E, 1px 0 #0A356E, 0 -1px #0A356E;
}

.lodi_description {
    flex-basis: 100%;
    text-align: center;
}

.header_container_nft .lodi_description span {
    text-align: center;
    width: 60%;
    color: #FFF;
    font-weight: normal;
    text-shadow: none;

}

.social_media_nft {
    box-sizing: border-box;
    justify-content: space-around;
    list-style-type: none;
    display: flex;
    align-self: center;

}

.social_media_nft li {
    padding-right: 2rem;
}

.yellow_svg {
    display: none;
}

.social_media_nft li:hover img:nth-child(1) {
    display: none;
}


.social_media_nft li:hover .yellow_svg {
    display: block;
}

.images_box_nft {
    align-items: baseline;
}

.header_container_nft .viewbox {
    text-transform: uppercase;
    padding: 10px 0;
    box-sizing: border-box;
    justify-content: space-around;
    list-style-type: none;
    display: flex;
    background-color: #FFFFFF;
    width: 330px;
    border-radius: 25px;
    box-shadow: -2px 0 black, 0 3px black, 1px 0 black, 0 -1px black;
    align-self: center;
    font-weight: 800;
    margin-top: 5rem;
}

.header_container_nft .viewbox a {
    text-decoration: none;
    color: #0A356E;
}

.header_bottom_nft {
    flex-direction: column;
    align-items: center;
}

.arrow_nft {
    width: 40px;
    height: 30px;
    margin-top: 3rem;
}