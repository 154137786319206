.mobile_header_wrapper {
    width: 100%;
    height: 120px;
    background-image: url('/src/images/mainLogo.jpg');
    background-position: center;
    background-position-y: -60px;
    background-position-x: -94rem;
    background-repeat: no-repeat;
}

.mobile_header_container {
    display: flex;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
    padding-top: 1.6rem;
}

.mobile_header_container>div {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.mobile_social_media {
    padding: 10px 0px;
    box-sizing: border-box;
    justify-content: space-around;
    list-style-type: none;
    display: flex;
    background-color: #FFFFFF;
    width: 90%;
    border-radius: 25px;
    box-shadow: -2px 0 black, 0 3px black, 1px 0 black, 0 -1px black;
    margin: 25px auto;
}

.mobile_header_info {
    width: 90%;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    justify-content: space-between;
    padding-left: 0;
    margin: 0 auto;
    box-sizing: border-box;
}

.mobile_header_info li {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-basis: 23%;
    height: 120px;
    background-color: #FDE512;
    border-radius: 15px;
    box-shadow: -2px 0 #815A00, 0 3px #815A00, 1px 0 #815A00, 0 -1px #815A00;
    padding: 20px 0;
    font-size: 1.1rem;
    margin-bottom: 1rem;
}