.photo_reports_container {
    width: 80%;
    margin: 0 auto;
    margin-top: 4rem;
}

table td {
    padding: 10px 10px 10px 0;

}

.photo_reports_title {
    color: #318BFF;
    font-size: 2.2rem;
    font-weight: 800;
    text-transform: uppercase;
}

.photo_reports_img {

    border-radius: 25px;
    box-shadow: -4px 0 black, 0 1px black, 1px 0 black, -4px 4px black;
    transition: all 0.2s;
}

.photo_reports_img:hover {
    cursor: pointer;
    box-shadow: none;
}

.modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    transition: visibility .4s ease, opacity .4s ease, transform .5s ease-in-out;
    align-items: center;
    visibility: hidden;
    transform: scale(0);
    opacity: 1;
    overflow: hidden;
    z-index: 999;
}

.modal.open {
    width: 100%;
    height: 100%;
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.pl {
    position: relative;
    bottom: 40px;
    align-self: center;
    box-sizing: border-box;
}