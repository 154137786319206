.mobile_what_we_do_wrapper {
    background-image: url('/src/images/wwdbg.jpg');
    height: 800px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-top: 4px solid #A47400;
    border-bottom: 4px solid #A47400;
    margin-top: 2rem;
}

.mobile_what_we_do_title {
    text-align: center;
    font-size: 1.7rem;
    font-weight: 800;
    color: #FFFF;
    text-shadow: -2px 0px #A47400, -1px 2px #A47400, 1px 0 #A47400, 0 -1px #A47400;
    padding: 2rem 0;
}

.mobile_what_we_do_container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.mobile_what_we_do_container>div {
    display: flex;
    flex-basis: 100%;
    height: 140px;
    background-color: #FFFF;
    margin-bottom: 1.5rem;
    border-radius: 25px;
    box-shadow: -4px 0 #A47400, 0 1px #A47400, 1px 0 #A47400, -4px 4px #A47400;
}

.mobile_what_we_do_photo {
    align-self: center;
    padding-left: 1rem;
    width: 100px;
    height: 100px;
}

.mobile_what_we_do_info {
    display: flex;
    flex-direction: column;
    color: #001B56;
    justify-content: center;
    font-size: 0.8rem;
    padding-left: 10px;
}

.mobile_what_we_do_info span:nth-child(1) {
    font-weight: 800;
    text-transform: uppercase;
}