.other_projects_container {
    width: 80%;
    margin: 0 auto;
    margin-top: 3rem;
}

.other_projects_title {
    display: inline-block;
    color: #318BFF;
    font-size: 2.2rem;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 3rem;
}

.other_projects_blocks {
    display: flex;
    justify-content: space-between;
    padding-bottom: 7rem;
}

.other_projects_blocks>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-around;
    flex-basis: 47%;
    height: 450px;
    border-radius: 25px;
    box-shadow: -3px 5px #001B56, 0 1px #001B56, 1px 0 #001B56, 0 -1px #001B56;
}

.other_projects_blocks>div:nth-child(1) {
    background-image: url('/src/images/OtherProjects/bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
}

.other_projects_blocks>div:nth-child(2) {
    background-color: #298FFF;
}

.footer_info {
    display: flex;
    flex-direction: column;
    padding-top: 10rem;
    width: 80%;
    margin: 0 auto;
    color: #FFFF;
    justify-content: center;
}

.footer_divider {
    width: 47px;
    height: 4px;
    background-color: #FDE512;
    border: 1px solid #A47400;
    border-radius: 10px;
    margin-bottom: 10px;
}

.footer_info_title {
    font-weight: 800;
}

.footer_sub_title {
    font-weight: normal;
    font-size: 1rem;
}

.center {
    padding-top: 0;
}

.nft_link {
    display: inline-flex;
    height: 100%;
    text-decoration: none;
}