.mobile_video_reports_container {
    width: 80%;
    margin: 0 auto;
    margin-top: 1rem;
}

.mobile_video_reports_title {
    display: inline-block;
    font-size: 1.3rem;
    color: #318BFF;
    text-transform: uppercase;
    font-weight: 800;
    padding-bottom: 1rem;
}

.dot_container {
    display: flex;
}

.mobile_dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0px -1px black, -2px 2px black, 1px 0 black, -1px 0px black;
    margin: 0 5px;
    background: #FFFF;
    cursor: pointer;
}

.mobile_dot.active:nth-child(odd) {
    background: #386CBB;
}

.mobile_dot.active:nth-child(even) {
    background: #F6DD00;
}

.hHJTFD {
    margin: 0 !important;
}
 