.mobile_photo_reports_container {
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    padding-bottom: 2rem;
}

.mobile_photo_reports_title {
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 800;
    color: #318BFF;
}

.mobile_slider_container {
    width: 100%;
    height: 300px;
    overflow: hidden;
    box-shadow: -4px 0 black, 0 1px black, 1px 0 black, -4px 4px black;
    border-radius: 15px;
    margin: 10px 0;
    position: relative;
}

.slide {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: all ease-in-out 0.5s;
}

.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.active-anim {
    opacity: 1;
    transition: all ease-in-out 0.5s;
}

.container-dots {
    position: relative;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    margin-top: 1.3rem;
}

.dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0px -1px black, -2px 2px black, 1px 0 black, -1px 0px black;
    margin: 0 5px;
    background: #FFFF;
    cursor: pointer;
}

.dot.active:nth-child(odd) {
    background: #386CBB;
}

.dot.active:nth-child(even) {
    background: #F6DD00;
}