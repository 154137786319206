.mobile_footer_wrapper {
    padding: 1rem 0;
    height: fit-content;

}

.mobile_footer_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    align-items: center;
}

.layer1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.mobile_footer_btn {
    width: 170px;
    height: 45px;
    border-radius: 25px;
    font-weight: 800;
    cursor: pointer;
    color: #FFFFFF;
    background-color: #FDE512;
    border-color: #A47400;
    box-shadow: -1px 3px #A47400, 0 1px #A47400, 1px 0 #A47400, 0 -1px #A47400;
    font-size: 1rem;
    border-image: none;
    border-width: 0;
}

.mobile_footer_btn:active {
    box-shadow: -1px 0px #A47400, 0 1px #A47400, 1px 0 #A47400, 0px -1px #A47400;
}

.layer2 {
    text-align: justify;
    margin-top: 1.2rem;
}

.layer3 {
    width: 100%;
}

.mobile_social_media_footer {
    padding: 10px 0px;
    box-sizing: border-box;
    justify-content: space-around;
    list-style-type: none;
    display: flex;
    width: 90%;
    margin: 25px auto;
}